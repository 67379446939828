export const ScreensList = '/screen/screens';
export const ScreenCreate = '/screen/screens/create';
export const ScreenEdit = '/screen/screens/edit';
export const ScreenCopy = '/screen/screens/copy';
export const TemplatesLink = '/screen/templates';

export const BranchesList = '/test/branches';
export const BranchesCreate = '/test/branches/create';
export const LinksList = '/test/links';
export const CreateLink = '/test/links/create';
export const EditLink = '/test/links/edit';
export const CopyLink = '/test/links/copy';
export const CreateFlow = '/test/links/createFlow';

export const CreateProductPlans = '/processing/product-plans/create';
export const ProductPlansList = '/processing/product-plans';
export const EditProductPlan = '/processing/product-plans/edit';
export const CopyProductPlan = '/processing/product-plans/copy';
export const ProductCodesLink = '/processing/product-codes';
export const VatRateList = '/processing/vat-rate';

export const AuditLogs = '/admin/audit-log';
export const ProjectsLink = '/admin/projects';
export const UsersLink = '/admin/users';
export const NotificationsLink = '/admin/notifications';

export const LocalizationsLink = '/content/localizations';
export const ThemesLink = '/content/themes';

export const LTVCoefficientList = '/analytics/ltv-coefficient';
