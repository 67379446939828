import { Auth } from 'aws-amplify';

import { checkNewNotification } from 'pages/notifications/helpers';

import config from 'config';

interface RequestOptions extends RequestInit {
    params?: Record<string, string>;
}

interface ErrorResponse {
    status: number;
    data: unknown;
}

export const request = async (input: RequestInfo | URL, options?: RequestOptions): Promise<any> => {
    try {
        const token = await getToken();
        const { url, fetchOptions } = prepareRequest(input, options, token);

        const response = await fetch(url, fetchOptions);

        await checkNewNotification(response);

        return response.ok ? response.json() : await handleErrorResponse(response);
    } catch (error) {
        return handleRequestError(error);
    }
};

const getToken = async (): Promise<string> => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
};

const prepareRequest = (input: RequestInfo | URL, options?: RequestOptions, token?: string): { url: string; fetchOptions: RequestInit } => {
    const { params, method, body, ...fetchOptions } = options ?? {};

    const headers = {
        Authorization: `Bearer ${token}`,
        'x-api-key': config.API_KEY,
        version: '1',
        ...(body && (method === 'POST' || method === 'PUT') ? { 'Content-Type': 'application/json' } : {}),
        ...fetchOptions?.headers,
    };

    const url = buildUrl(input, params);

    return { url, fetchOptions: { ...fetchOptions, headers, method, body } };
};

const buildUrl = (input: RequestInfo | URL, params?: Record<string, string>): string => {
    const paramsString = params ? `?${new URLSearchParams(params)}` : '';
    return typeof input === 'string' ? `${config.API_URL}${input}${paramsString}` : input.toString();
};

const handleErrorResponse = async (response: Response): Promise<ErrorResponse> => {
    const errorData = await response.json().catch(() => response);
    throw { status: response.status, data: errorData };
};

const handleRequestError = (error: unknown): Promise<ErrorResponse> => {
    if (error instanceof Error) {
        return Promise.reject({ status: undefined, data: undefined });
    }
    return Promise.reject(error);
};
