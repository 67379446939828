import React, { createContext, useContext, useEffect, useState } from 'react';

import { NEW_NOTIFICATION_EVENT_NAME } from 'pages/notifications/constants';
import { NotificationFull } from 'pages/notifications/types';

import { useGetNotificationById } from '../hooks/api/notifications/useGetNotificationById';

interface NotificationContextProps {
    notification: NotificationFull | null;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [notificationId, setNotificationId] = useState<string | null>(localStorage.getItem('notificationId') || null);
    const [notification, setNotification] = useState<NotificationFull | null>(null);

    useGetNotificationById({
        id: notificationId,
        onSuccess: (data) => setNotification(data),
        onError: () => setNotification(null),
    });

    useEffect(() => {
        const handleNotificationEvent = (event: CustomEvent) => {
            const { notificationId } = event.detail;
            setNotificationId(notificationId);
        };

        window.addEventListener(NEW_NOTIFICATION_EVENT_NAME, handleNotificationEvent as EventListener);

        return () => {
            window.removeEventListener(NEW_NOTIFICATION_EVENT_NAME, handleNotificationEvent as EventListener);
        };
    }, []);

    return <NotificationContext.Provider value={{ notification }}>{children}</NotificationContext.Provider>;
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
