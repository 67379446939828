import { useQuery } from 'react-query';

import { request } from 'api/request';

import { logger } from 'utils/logger';
import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { NO_NOTIFICATION_ID } from 'pages/notifications/constants';
import { NotificationFull } from 'pages/notifications/types';

import { BaseHookProps, ERROR_DATA } from '../type';

interface GetNotificationByIdParams extends BaseHookProps<any> {
    id: string | null;
}

const apiRequest = async (id: string | null) => {
    if (!id || id === NO_NOTIFICATION_ID) {
        return;
    }
    return await request(`admin/release-notification/${id}`);
};

type NotificationListResponse = {
    items: Array<NotificationFull>;
    _meta: {
        page: number;
        per_page: number;
        total_pages: number;
        total_items: number;
    };
};

const useGetNotificationById = ({ onError, onSettled, onSuccess, id }: GetNotificationByIdParams) => {
    return useQuery<NotificationListResponse, Promise<ERROR_DATA<unknown>>>(['notifications', id], async () => apiRequest(id), {
        refetchOnWindowFocus: false,
        enabled: Boolean(id),
        onSettled,
        onSuccess,
        onError: async (err) => {
            const error = await err;
            onError && onError(error);

            logger.error(Error(`Error fetching notification by ${id}:`), {
                cause: error,
                tags: {
                    reason: ERROR_REASONS.notificationById,
                    errorCode: error.status,
                },
            });
        },
    });
};

export { useGetNotificationById };
