import React from 'react';

import { Notification } from 'pages/notifications/types';

import { InfoOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Typography } from '@mui/material';

const NotificationBanner = ({ notification, onClose }: { notification: Notification; onClose: () => void }) => {
    return (
        <Alert
            icon={<InfoOutlined fontSize="inherit" />}
            severity="error"
            variant="filled"
            onClose={onClose}
            sx={{
                py: 0,
                px: '8px',
                display: 'flex',
                alignItems: 'flex-start',
                marginTop: '60px',
                '& .MuiAlert-icon': {
                    mr: '8px',
                    mt: '18px',
                },
                '& .MuiAlert-message': {
                    paddingTop: '20px',
                    paddingBottom: '20px',
                },
            }}
            aria-haspopup="true"
        >
            <AlertTitle sx={{ m: 0, display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>{notification.title}</AlertTitle>
            <Typography sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }} variant="body2">
                {notification.text}
            </Typography>
        </Alert>
    );
};

export { NotificationBanner };
