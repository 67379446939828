import { useEffect, useMemo, useState } from 'react';

import { useNotification } from 'providers/NotificationProvider';

import { NO_NOTIFICATION_ID } from 'pages/notifications/constants';

export const useNotificationBanner = () => {
    const { notification } = useNotification();
    const [showBanner, setShowBanner] = useState(true);

    const computedShowBanner = useMemo(() => {
        if (notification?.id === NO_NOTIFICATION_ID || !notification) {
            return false;
        }

        const dismissedNotificationId = localStorage.getItem('dismissedNotificationId');
        return notification.id !== dismissedNotificationId;
    }, [notification]);

    useEffect(() => {
        setShowBanner(computedShowBanner);
    }, [computedShowBanner]);

    const closeBanner = () => {
        if (notification) {
            localStorage.setItem('dismissedNotificationId', notification.id);
        }
        setShowBanner(false);
    };

    return { notification, showBanner, closeBanner };
};
