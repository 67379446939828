import * as yup from 'yup';

export const notificationValidationSchema = yup.object({
    title: yup.string().trim().required('Title required'),
    text: yup.string().trim().required('Text required'),
    show_from: yup.string().trim().required('Time required'),
    show_to: yup.string().trim().required('Time required'),
    type: yup.string(),
    level: yup.string(),
});

export const NEW_NOTIFICATION_EVENT_NAME = 'NEW_NOTIFICATION_EVENT';
export const NO_NOTIFICATION_ID = 'no-notification';
