import { createContext, useEffect, useState } from 'react';

import { NotificationProvider } from 'providers/NotificationProvider';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import Drawer from './Drawer';
import Header from './Header';
import Main from './Main';

export const MenuContext = createContext<{
    isDrawerOpen: boolean;
    openItem: string | null;
    setOpenItem: React.Dispatch<React.SetStateAction<string | null>> | null;
}>({
    isDrawerOpen: false,
    openItem: null,
    setOpenItem: null,
});

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    // drawer toggler
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openItem, setOpenItem] = useState<string | null>('dashboard');
    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    // set media wise responsive drawer
    useEffect(() => {
        setIsDrawerOpen(!matchDownLG);
    }, [matchDownLG]);

    return (
        <MenuContext.Provider value={{ isDrawerOpen, openItem, setOpenItem }}>
            <NotificationProvider>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Header open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} />
                    <Drawer open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} />
                    <Main />
                </Box>
            </NotificationProvider>
        </MenuContext.Provider>
    );
};

export default MainLayout;
