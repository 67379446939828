import { Outlet } from 'react-router-dom';

import { useNotificationBanner } from 'hooks/notifications/useNotificationBanner';

import { NotificationBanner } from 'components/NotificationBanner';

import { Box, Toolbar } from '@mui/material';

import Breadcrumbs from 'ui-elements/@extended/Breadcrumbs';

import navigation from 'menu-items';

const Main = () => {
    const { notification, showBanner, closeBanner } = useNotificationBanner();

    return (
        <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            {notification && showBanner && <NotificationBanner notification={notification} onClose={closeBanner} />}
            <Toolbar />
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
        </Box>
    );
};

export default Main;
